<template>
  <v-dialog v-model="dialog" max-width="800px" @input="handleDialogInput">
    <v-card>
      <v-card-title>{{ isNewDispatcher ? 'Add New Dispatcher' : 'Update Dispatcher' }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="form.firstName" label="First Name" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.surName" label="Last Name" :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="form.companyName" label="Company Name" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.totalTrucks" label="Total Trucks" :rules="[rules.required, rules.number]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="form.totalDrivers" label="Total Drivers" :rules="[rules.required, rules.number]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.email" label="Email" :rules="[rules.required, rules.email]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="form.phone" label="Phone" :rules="[rules.required, rules.phone]"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="form.password" label="Password" type="password" :rules="[rules.required, rules.password]" v-if="isNewDispatcher"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field v-model="form.companyLink" label="Company Link"></v-text-field>
            </v-col>

            <v-col cols="12" class="relative-position">
              <h4>Company Logo</h4>
              <input type="file" @change="onFileChange" accept="image/*">
              <v-img v-if="form.companyLogo" :src="form.companyLogo" aspect-ratio="1" class="position-relative"  style="margin: 10px; width: 100px; height: 100px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="form.companyLogo"
                      v-bind="attrs"
                      v-on="on"
                      class="position-absolute top-0 right-0 remove-icon"
                      @click="removeCompanyLogo"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                  <span v-if="form.companyLogo">Remove Image</span>
                </v-tooltip>
              </v-img>
            </v-col>
          </v-row>

        </v-form>

        <v-alert v-if="error" type="error">{{ error }}</v-alert>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="handleDialogInput(null)" :disabled="uploading">Cancel</v-btn>
        <v-btn color="primary" @click="onSubmit" :disabled="uploading">
          <v-progress-circular
            v-if="uploading"
            indeterminate
            size="20"
            color="white"
            class="ml-2"
          ></v-progress-circular>
          {{ uploading ? 'Processing...' : (isNewDispatcher ? 'Add' : 'Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addDispatcher, updateDispatcher, uploadImage, getDispatcherById } from '@/services/admin';

export default {
  props: {
    dispatcherId: String
  },
  data() {
    return {
      dialog: false,
      uploading: false,
      selectedFile: null,
      form: {
        firstName: '',
        surName: '',
        companyName: '',
        totalTrucks: 0,
        totalDrivers: 0,
        email: '',
        phone: '',
        password: '',
        companyLink: '',
        companyLogo: ''
      },
      rules: {
        required: (value) => !!value || 'Field is required',
        email: (value) => /.+@.+\..+/.test(value) || 'Email must be valid',
        number: (value) => !isNaN(parseFloat(value)) && isFinite(value) || 'Value must be a number',
        phone: (value) => /^\+\d{10,15}$/.test(value) || 'Phone number must be valid',
        password: (value) => value.length >= 6 || 'Password must be at least 6 characters'
      },
      isNewDispatcher: true,
      error: null,
    };
  },
  watch: {
    dispatcherId(newId, oldId) {
      // console.log('newId, oldId', newId, oldId);
      if (newId && (newId !== oldId)) {
        this.isNewDispatcher = false;
        this.fetchDispatcher(newId);
      }
    }
  },
  methods: {
    async fetchDispatcher(id) {
      try {
        const response = await getDispatcherById(id);
        const { company, ...dispatcherData } = response.data;
        this.form = {
          ...dispatcherData,
          companyName: company?.name,
          totalTrucks: +company?.totalTrucks,
          totalDrivers: +company?.totalDrivers,
          companyLogo: company?.logo,
          companyLink: company?.link
        };
      } catch (error) {
        console.error('Error fetching dispatcher:', error);
        this.error = error.message || 'An error occurred';
      }
    },
    async onSubmit() {
      try {
        this.uploading = true;
        if (this.selectedFile) {
          const formData = new FormData();
          formData.append('image', this.selectedFile);
          const response = await uploadImage(formData);
          this.form.companyLogo = response.data.url;
        }

        this.form.totalDrivers = +this.form.totalDrivers
        this.form.totalTrucks = +this.form.totalTrucks

        let response;
        if (this.isNewDispatcher) {
          response = await addDispatcher(this.form);
        } else {
          response = await updateDispatcher(this.form._id, this.form);
        }

        console.log('Response:', response);

        if (response.error) {
          if (response.message && response.message.length > 0) {
            const errorMessage = response.message.join(', ');
            throw new Error(errorMessage);
          } else {
            throw new Error(response.error || 'Unknown error occurred');
          }
        }
        // console.log(`${this.isNewDispatcher ? 'Dispatcher added' : 'Dispatcher updated'} successfully`);
        this.dialog = false;
        this.$emit('dispatcherAdded');
        this.handleDialogInput(null)
      } catch (error) {
        if (error.response && error.response.data) {
          const responseData = error.response.data;
          if (responseData.message && Array.isArray(responseData.message)) {
            this.error = responseData.message.join(', ');
          } else if (responseData.error) {
            this.error = responseData.error;
          } else {
            this.error = 'An error occurred';
          }
        } else {
          this.error = error.message || 'An error occurred';
        }
        console.error(`Error ${this.isNewDispatcher ? 'adding' : 'updating'} dispatcher:`, error);
        // this.error = error.message || 'An error occurred';
      } finally {
        this.uploading = false;
      }
    },
    clearForm() {
      // console.log('this.form', this.form);
      this.form = {
        firstName: '',
        surName: '',
        companyName: '',
        totalTrucks: 0,
        totalDrivers: 0,
        email: '',
        phone: '',
        password: '',
        companyLink: '',
        companyLogo: ''
      }

      this.isNewDispatcher = true
      this.error = null;
    },
    handleDialogInput(value) {
      // console.log('value', value);
      if (!value) {
        this.dialog = false;
        this.$emit('close');
        this.clearForm();
      }
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.previewCompanyLogo();
    },
    previewCompanyLogo() {
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.companyLogo = e.target.result;
        };
        reader.readAsDataURL(this.selectedFile);
      }
    },

    removeCompanyLogo() {
      this.form.companyLogo = '';
      this.selectedFile = null;
    }
  }
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.remove-icon {
  color: red;
  /* Change the color to red */
}

/* Style the tooltip */
.tooltip {
  font-size: 12px;
  /* Adjust font size as needed */
}
</style>