<template>
  <div class="tab-navigation">
    <router-link
      v-for="tab in tabs"
      :key="tab.path"
      :to="getTabPath(tab)"
      class="tab-link"
      :class="{ active: isTabActive(tab) }"
    >
      {{ tab.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    currentDispatcherId: {
      type: String,
      default: ''
    }
  },
  methods: {
    isTabActive(tab) {
      return this.$route.path === this.getTabPath(tab);
    },
    getTabPath(tab) {
      return this.currentDispatcherId ? `${tab.path}/${this.currentDispatcherId}` : tab.path;
    }
  }
}
</script>

<style scoped>
.tab-navigation {
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.tab-link {
  text-decoration: none;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-right: 5px;

}

.tab-link:hover {
  background-color: #f0f0f0;
}

.tab-link.active {
  background-color: #4CAF50;
  color: #fff;
  font-weight: bold;
}
</style>
