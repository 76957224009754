<template>
  <v-container>
    <v-row>
      <div class="tabs-div">
        <TabNavigation :tabs="tabs" :currentDispatcherId="this.$route.params.id"/>
      </div>
    </v-row>

    <div class="drivers-table">

      <v-row justify="space-between">
        <v-col cols="6">
          <v-btn-toggle v-model="filters.status" color="primary">
            <v-btn text small value="All" style="height: 50px;">All</v-btn>

            <v-btn text small value="active" style="height: 50px;">
              Active
            </v-btn>
            <v-btn text small value="Assigned" style="height: 50px;">
              Confirmed
            </v-btn>
            <v-btn text small value="InProgress" style="height: 50px;">
              InProgress
            </v-btn>
            <v-btn text small value="Expired" style="height: 50px;">
              Expired
            </v-btn>
            <v-btn text small value="Completed" style="height: 50px;">
              Completed
            </v-btn>
            <v-btn text small value="Cancelled" style="height: 50px;">
              Cancelled
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="6" style="margin-top: 8px">
          <v-row>
            <div style="max-width: 300px;">
              <date-filter :init-date="initDate" @dateChange="onDateChange" />
            </div>

            <v-text-field label="Search Bookings" solo append-icon="mdi-magnify" v-model="filters.search"  style="margin-right:12px"/>
            <!-- <a href="#" @click.prevent="
              exportToCSV()
              ">
              <v-btn color="primary" style="margin:5px; ">
                <v-icon small> mdi-download </v-icon>
                Export
              </v-btn>
            </a> -->
          </v-row>
        </v-col>
      </v-row>

      <v-card class="mt-15">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Booking Details
            </div>
          </template>
          <v-data-table :headers="headers" :items="requests" :options.sync="tableOptions" :loading="loading"
            :server-items-length="totalItems"
            map-type="roadmap" class="elevation-1" :footer-props="{
              'items-per-page-options': tableOptions.rowsPerPageItems,
            }">
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ getReadableDate(item.createdAt) }}
            </template>
            <template v-slot:[`item._id`]="{ item }">
              {{ constructId(item._id) }}
            </template>
            <template v-slot:[`item.requestType`]="{ item }">
              {{ getReadableRequestType(item.requestType) }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'active'" class="ma-2" color="orange" text-color="white" v-bind="attrs"
                    v-on="on">
                    Active
                  </v-chip>
                </template>
                <span>
                  Qoute has been created, yet to accept by customer.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'Arrived'" class="ma-2" color="#EA8FEA" text-color="white" v-bind="attrs"
                    v-on="on">
                    Arrived
                  </v-chip>
                </template>
                <span>
                  Driver arrived at the pickup location.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'driver_started'" class="ma-2" color="#A0D8B3" text-color="white" v-bind="attrs"
                    v-on="on">
                    Driver Started
                  </v-chip>
                </template>
                <span>
                  Driver started the ride.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'Completed'" class="ma-2" color="green" text-color="white" v-bind="attrs"
                    v-on="on">
                    Completed
                  </v-chip>
                </template>
                <span>
                  Ride completed successfully.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'Cancelled'" class="ma-2" color="red" text-color="white" v-bind="attrs"
                    v-on="on">
                    Cancelled
                  </v-chip>
                </template>
                <span>
                  Booking canceled either by driver or customer.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'Assigned'" class="ma-2" color="light-blue lighten-1" text-color="white"
                    v-bind="attrs" v-on="on">
                    Assigned
                  </v-chip>
                </template>
                <span>
                  Ride has been assigned to a driver.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'Expired'" class="ma-2" color="deep-orange darken-4" text-color="white"
                    v-bind="attrs" v-on="on">
                    Expired
                  </v-chip>
                </template>
                <span>
                  Time out, no driver has accepted ride during the breakdown time.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'InProgress'" class="ma-2" color="lime accent-1" text-color="white"
                    v-bind="attrs" v-on="on">
                    In Progress
                  </v-chip>
                </template>
                <span>
                  Ride in progess.
                </span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.status == 'keysPickedUp'" class="ma-2" color="cyan" text-color="white" v-bind="attrs"
                    v-on="on">
                    Keys Picked Up
                  </v-chip>
                </template>
                <span>
                  Rider has picked up the keys and on the way to customer
                  location.
                </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip :key="index" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="goToRequest(item._id)">
                    mdi-eye
                  </v-icon>
                </template>
                View
              </v-tooltip>
            </template>
            <template v-slot:[`item.pickup.address`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ getShortAddress(item.completePickupAddress) }}
                  </div>
                </template>
                <span>{{ item.completePickupAddress }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.dropoff.address`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ getShortAddress(item.completeDropoffAddress) }}
                  </div>
                </template>
                <span>{{ item.completeDropoffAddress }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-card>
          
   </div>
  </v-container>
</template>

<script>
import {
  getDispatcherRequest,
} from "@/services/admin";

import DateFilter from "@/components/dateFilter";
import AddUpdateDispatcherDialog from '@/components/addDispatcherForm';
import TabNavigation from '@/components/TabNavigation.vue';

function debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export default {
  components: { DateFilter, AddUpdateDispatcherDialog, TabNavigation },
  data() {
    return {

      tabs: [
        { name: 'General', path: '/dispatcher-general-details' },
        { name: 'Bookings', path: '/dispatcher-bookings' },
        { name: 'Drivers', path: '/dispatcher-drivers' },
        { name: 'Vehicles', path: '/dispatcher-vehicles' },
        { name: 'Transactions', path: '/dispatcher-transactions' }
      ],

      loading: false,
      requests: [],

      filters: {
        startDate: null,
        endDate: null,
        status: "All",
        search: '',
        order: 'DESC'
      },
      statusOptions: ['All', 'Active', 'Pending', 'Incomplete', 'Blocked', 'Deleted'],
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: '_id',
        },
        {
          sortable: false,
          text: 'Customer',
          value: 'customer',
        },
        {
          sortable: false,
          text: 'Pickup Location',
          value: 'pickup.address',
        },
        {
          sortable: false,
          text: 'Dropoff Location',
          value: 'dropoff.address',
        },
        {
          sortable: false,
          text: 'Request Type',
          value: 'requestType',
          width: '120px',
        },
        // {
        //   sortable: false,
        //   text: 'Price',
        //   value: 'price'
        // },
        {
          sortable: false,
          text: 'Created on',
          value: 'createdAt',
        },
        {
          sortable: false,
          text: 'Driver Activity',
          value: 'rideStatus',
          width: '110px',
        },
        {
          sortable: false,
          text: 'status',
          value: 'status',
          width: '110px',
        },
        {
          text: 'Action',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],

      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [10, 30, 50, 100],
      },

      totalItems: 10,

      initDate: [],
      usersStatus: "all",
      stats: null,
      selectedDispatcherId: null

    };
  },
  created() {
    this.fetchDispatcherRequests();
    // this.fetchDispatcherStats();
  },
  watch: {
    'tableOptions': {
      handler: 'fetchDispatcherRequests',
      deep: true
    },

    // usersStatus() {
    //   this.filters.status = this.usersStatus
    //   this.fetchDispatcherRequests();
    // },

    'filters.status': {
      handler(newStatus, oldStatus) {
        if (newStatus !== oldStatus) {
          this.fetchDispatcherRequests();
        }
      },
      deep: true
    },

    'filters.search': {
      handler: debounce(async function (newValue) {
        this.fetchDispatcherRequests();
      }, 500),
      immediate: false
    },
  },
  methods: {
    async fetchDispatcherRequests() {
      try {
        this.loading = true;
        const response = await getDispatcherRequest({
          ...this.tableOptions,
          ...this.filters,
          take: this.tableOptions.itemsPerPage,
          status: this.filters.status === 'All' ? null : this.filters.status
        }, this.$route.params.id);

        let resultBooking = []
        response.data.data?.forEach((element, i) => {
          let row = {
            _id: element._id,
            customer: `${element.customer?.firstName}  ${element.customer?.surName}`,
            'pickup.address': element.pickup.address,
            completePickupAddress: element.pickup.address,
            'dropoff.address': element.dropoff.address,
            completeDropoffAddress: element.dropoff.address,
            requestType: element.requestType,
            status: element.status,
            createdAt: element.createdAt,
            rideStatus: element.requestType == 'breakdown' ? this.getCorrectStatusTextBreakdown(element) : this.getCorrectStatusTextRelocate(element)
          }
          resultBooking.push(row)
        })

        this.requests = resultBooking;

        this.totalItems = response.data.meta.itemCount;
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        this.loading = false;
      }
    },

    onDateChange(newValue) {
      // console.log('newValue', newValue);
      if (!newValue || newValue.length !== 2) return;

      // Convert the strings to Date objects
      const startDate = newValue[0] ? new Date(newValue[0]) : null;
      const endDate = newValue[1] ? new Date(newValue[1]) : null;

      // Update the filters with the new start and end dates
      this.filters.startDate = startDate ? startDate.toISOString().split('T')[0] : '';
      this.filters.endDate = endDate ? endDate.toISOString().split('T')[0] : '';

      // Fetch drivers with the updated filters
      this.fetchDispatcherRequests();
      // this.fetchDispatcherStats();
    },


    goToProfile(id) {
      this.$router.push({ name: "Driver Profile", params: { id: id } });
    },

    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },

    constructId(id) {
      return (id?.slice(17, 19) + "-" + id?.slice(19)).toUpperCase()
    },

    getReadableRequestType(type) {
      if (type === 'RelocateNow') {
        return 'Relocate Now'
      } else if (type === 'breakdown') {
        return 'Break Down'
      } else if (type === 'RelocateScheduled') {
        return 'Relocate Scheduled'
      } else return type
    },

    goToRequest(id) {
      this.$router.push({ name: 'Request Detail', params: { id: id } })
    },

    getShortAddress(address) {
      return address.length > 25 ? `${address?.slice(0, 25)}...` : address
    },

    getCorrectStatusTextBreakdown(requestDetails) {
      console.log('called 1 ', requestDetails.status);
      if (requestDetails.status == 'Assigned' || requestDetails.status == 'active') {
        return 'Winch not started'
      } else if (requestDetails.status == 'driver_started') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'Arrived') {
        return 'Arrived at vehicle pickup'
      } else if (requestDetails.status == 'InProgress') {
        return 'En route to vehicle dropoff'
      } else if (requestDetails.status == 'Completed') {
        return 'Ride is Completed'
      } else {
        return 'Ride is ' + (requestDetails.status).toUpperCase()
      }
    },

    getCorrectStatusTextRelocate(requestDetails) {
      console.log('called 2 ', requestDetails.status);
      if (requestDetails.status == 'Assigned' || requestDetails.status == 'active') {
        return 'Winch not started'
      } else if (requestDetails.status == 'driver_started' && requestDetails?.isKeyAtPickupLocation == 'Yes') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'driver_started' && requestDetails?.isKeyAtPickupLocation !== 'Yes') {
        return 'En route to key pickup'
      } else if (requestDetails.status == 'Arrived' && requestDetails?.isKeyAtPickupLocation == 'Yes') {
        return 'Arrived at vehicle pickup'
      } else if (requestDetails.status == 'Arrived' && requestDetails?.isKeyAtPickupLocation !== 'Yes') {
        return 'Arrived at key pickup'
      } else if (requestDetails.status == 'keysPickedUp') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'InProgress') {
        return 'En route to vehicle dropoff'
      } else if (requestDetails.status == 'Completed') {
        return 'Ride is Completed'
      } else {
        return 'Ride is ' + (requestDetails.status).toUpperCase()
      }
    },
  },


};
</script>

<style scoped>
.tabs-div {
  width: 100%;
  padding: 20px;
}

.drivers-table {
  width: 100%;
  padding: 0.7rem;
}
</style>