import { http } from './http-common'
import axios from 'axios'


export const getPayouts = (payload) => {
  return http.get('/api/admin/transactions/pendingDriverPayouts', { params: payload })
}

export const createDriver = (payload) => {
  return http.post('/api/admin/drivers/add-driver', payload)
}

export const addPhoto = (type, payload) => {
  return http.post(`/document/${type}`, payload)
}

export const approvePayout = (payload) => {
  return http.put(`/api/admin/transactions/approveDriverPayout/${payload}`)
}

export const updateDriverLocation = (id, data) => {
  return http.put(`/api/admin/drivers/update-location/${id}`, data)
}

export const declinePayout = (payload) => {
  return http.put(`/api/admin/transactions/decline-payout/${payload}`)
}

export const partialPayout = (payload, data) => {
  return http.put(`/api/admin/transactions/partialProfessionalPayout/${payload}`, data)
}

export const getTransactions = (payload) => {
  return http.get('/api/admin/transactions/getAdminTransactions', { params: payload })
}

export const transactionsStats = (payload) => {
  return http.get('/transactions_stats', { params: payload })
}

export const bookingStats = (payload) => {
  return http.get('/booking_stats', { params: payload })
}

export const createIndustry = (payload) => {
  return http.post('/industry', payload)
}

export const updateIndustry = (id, payload) => {
  return http.put(`/industry/${id}`, payload)
}

export const getIndustries = (payload) => {
  return http.get('/industry', { params: payload })
}

export const createCompany = (payload) => {
  return http.post('/company', payload)
}

export const updateCompany = (id, payload) => {
  return http.put(`/company/${id}`, payload)
}

export const createSchool = (payload) => {
  return http.post('/school', payload)
}

export const updateSchool = (id, payload) => {
  return http.put(`/school/${id}`, payload)
}

export const getCompaies = (payload) => {
  return http.get('/company', { params: payload })
}

export const getSchools = (payload) => {
  return http.get('/school', { params: payload })
}

export const getPages = (payload) => {
  return http.get('/static_page', { params: payload })
}

export const createPage = (payload) => {
  return http.post('/static_page', payload)
}


export const sendNotification = (payload) => {
  return http.post('/api/admin/bespoke-notification', payload)
}

export const updatePage = (id, payload) => {
  return http.put(`/static_page/${id}`, payload)
}

export const getBookings = (payload) => {
  return http.get('/api/admin/requests', { params: payload })
}

export const getRequestLocations = (payload) => {
  return http.get('/api/admin/requests/rides/locations', { params: payload })
}

export const getRequest = (payload) => {
  return http.get(`/api/admin/requests/${payload}`)
}

export const getRequestLogs = (payload) => {
  return http.get(`/api/admin/requests/getAllRequestLogs/${payload}`)
}

export const getAllQuotesOnRequest = (payload) => {
  return http.post('/api/admin/quotes/getAllQuotesOnRequest', payload)
}

export const getBooking = (id) => {
  return http.get(`/api/admin/requests/${id}`)
}

export const createPackage = (payload) => {
  return http.post('/package', payload)
}

export const deletePackage = (id) => {
  return http.delete(`/package/${id}`)
}

export const deleteIndustry = (id) => {
  return http.delete(`/deleteindustry/${id}`)
}
export const deleteCompany = (id) => {
  return http.delete(`/deletecompany/${id}`)
}

export const deleteSchool = (id) => {
  return http.delete(`/deleteschool/${id}`)
}

export const createCoupon = (payload) => {
  return http.post('/api/admin/coupon/createCoupon', payload)
}

export const updatePackage = (id, payload) => {
  return http.put(`/package/${id}`, payload)
}

export const updateCoupon = (id, payload) => {
  return http.put(`/api/admin/coupon/updateCoupon/${id}`, payload)
}

export const deleteCoupon = (id, payload) => {
  return http.delete(`/api/admin/coupon/deleteCoupon/${id}`)
}

export const getCoupons = (payload) => {
  console.log("while calling", payload)
  return http.get('/api/admin/coupon/getCoupon', { params: payload })
}

export const getCouponByCode = (payload) => {
  return http.get(`/getCouponByCode/${payload}`)
}

export const getPackages = (payload) => {
  return http.get('/package', { params: payload })
}

export const createInviteCode = (payload) => {
  return http.post('/invite_code', payload)
}

export const updateInviteCode = (id, payload) => {
  return http.put(`/invite_code/${id}`, payload)
}

export const getInviteCodes = (payload) => {
  return http.get('/invite_code', { params: payload })
}



//panther eddition
export const updateCustomerVehicle = (id, payload) => {
  return http.put(`api/admin/customers/updateVehicle/${id}`, payload)
}

export const addNewVehicle = (payload) => {
  return http.post("api/admin/customers/addVehicle", payload)
}

//deleteVehicle/:id

export const deleteCustomerVehicle = (id, payload) => {
  return http.delete(`api/admin/customers/deleteVehicle/${id}`, payload)
}

export const beingAdminUpdateCustomer = (id, payload) => {
  return http.put(`api/admin/customers/updateUserData/${id}`, payload)
}


//route functions to handle drivers

export const addNewDriverVehicle = (payload) => {
  return http.post('api/admin/drivers/addvehicle', payload)
}

//id is vehicle id
export const updateDriverVehicle = (id, payload) => {
  return http.put(`api/admin/drivers/updateVehicle/${id}`, payload)
}
export const deleteDriverVehicle = (id, payload) => {
  return http.delete(`api/admin/drivers/deleteVehicle/${id}`, payload)
}
export const beingAdminUpdateDriver = (id, payload) => {
  return http.put(`api/admin/drivers/updateUserData/${id}`, payload)
}
export const updateDriverProfile = (id, payload) => {
  return http.put(`api/admin/drivers/update-driver-profile/${id}`, payload)
}

// export const uploadImage = (url, formData) => {
//   return http.put(url, formData, { headers: { "Content-Type": "multipart/form-data" } })
// }

export const uploadImage = (formData) => {
  return axios.post(
    `${process.env.VUE_APP_API}/api/admin/upload`
    , formData
    , { headers: { "Content-Type": "multipart/form-data" } }
  )
  // return http.post('api/admin/upload', formData, { headers: { "Content-Type": "multipart/form-data" } })
}




//route functions to get recent bookings
//to get rercent bookings
export const getAllCustomerRecentBooking = (id) => {
  return http.get(`api/admin/rides/allCustomerBookings/${id}`)
}
export const getAllDriverRecentBooking = (id) => {
  return http.get(`api/admin/rides/allDriverBookings/${id}`)
}


export const getLicenseDetails = (id) => {
  return http.get(`api/admin/drivers/getDriverProfile/${id}`)
}


export const getRequestDataInCSV = (payload) => {
  return http.get(`api/admin/requests/csv/all/request`, { params: payload })
}

export const getDriverLcationLogsDuringRide = (id) => {
  return http.get(`api/driver/myBookings/location-logs/${id}`)
}

export async function deleteRequest(id) {
  return http.delete(`api/admin/requests/${id}`);
}


export const getRequestDriversReceivedNotifications = (id) => {
  return http.get(`api/admin/driver-notifications/request-id/${id}`)
}









// below dispatcher routes

export const getDispatchers = (payload) => {
  return http.get('backend/api/admin/dispatchers', { params: payload });
}

export const addDispatcher = async (payload) => {
  return http.post('backend/api/admin/dispatchers', payload);
};

export const updateDispatcher = async (id, payload) => {
  return http.patch(`backend/api/admin/dispatchers/${id}`, payload);
};

export const getDispatcherById = async (id) => {
  return http.get(`backend/api/admin/dispatchers/${id}`);
};

export async function deleteDispatcher(id) {
  return http.delete(`backend/api/admin/dispatchers/${id}`);
}


export const getDispatcherStats = async () => {
  return http.get(`backend/api/stats/dispatchers`);
};

export const getDispatcherDrivers = (payload, id) => {
  return http.get(`backend/api/admin/dispatchers/${id}/drivers`, { params: payload });
}

export const getDispatcherRequest = (payload, id) => {
  return http.get(`backend/api/admin/requests/dispatcher/${id}`, { params: payload });
}

export const getDispatcherVehicles = (payload, id) => {
  return http.get(`backend/api/admin/vehicles/dispatcher/${id}`, { params: payload });
}

export async function deleteDispatcherDriver(id) {
  return http.delete(`backend/api/admin/dispatchers/driver/${id}`);
}